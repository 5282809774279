import { Directive, HostListener, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appFocusTracker]',
})
export class FocusTrackerDirective implements OnInit, OnDestroy {
  private static lastInteraction: 'keyboard' | 'mouse' | null = null;
  private static isListenerAdded = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (!FocusTrackerDirective.isListenerAdded) {
      document.addEventListener('keydown', this.onGlobalKeyDown, true);
      document.addEventListener('mousedown', this.onGlobalMouseDown, true);
      FocusTrackerDirective.isListenerAdded = true;
    }
  }

  ngOnDestroy(): void {
    if (FocusTrackerDirective.isListenerAdded) {
      document.removeEventListener('keydown', this.onGlobalKeyDown, true);
      document.removeEventListener('mousedown', this.onGlobalMouseDown, true);
      FocusTrackerDirective.isListenerAdded = false;
    }
  }

  private onGlobalKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Tab' || event.key.startsWith('Arrow')) {
      FocusTrackerDirective.lastInteraction = 'keyboard';
    }
  };

  private onGlobalMouseDown = (): void => {
    FocusTrackerDirective.lastInteraction = 'mouse';
  };

  @HostListener('focusin')
  onFocusIn(): void {
    if (FocusTrackerDirective.lastInteraction === 'keyboard') {
      this.renderer.setAttribute(this.el.nativeElement, 'data-focus-method', 'keyboard');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'data-focus-method');
    }
  }
}
